import moment from 'moment';

export class Holiday{
    constructor(public date: Date, public description: string){}
}

export class HolidayService{

    public createHolidays(year: number): Array<Holiday> {
        var holydays = new Array<Holiday>();
        var v = this.getEasterSunday(year);
        const easterSunday = new Date(year, v.month(), v.date())
        holydays.push(new Holiday( new Date(year,0,1),'1. nyttårsdag'));
        holydays.push(new Holiday( new Date(year,4,1),'1. mai'));
        holydays.push(new Holiday( new Date(year,4,17),'17. mai'));
        holydays.push(new Holiday( new Date(year,11,24),'Julaften'));
        holydays.push(new Holiday( new Date(year,11,25),'1. juledag'));
        holydays.push(new Holiday( new Date(year,11,26),'2. juledag'));
        holydays.push(new Holiday( new Date(year,11,31),'Nyttårsaften'));
        holydays.push(new Holiday(this.addDays(-3,easterSunday),'Skjærtorsdag'));
        holydays.push(new Holiday(this.addDays(-2,easterSunday),'Langfredag'));
        holydays.push(new Holiday(this.addDays(1,easterSunday),'2. påskedag'));
        holydays.push(new Holiday(this.addDays(39,easterSunday),'Kristi himmelfartsdag'));
        holydays.push(new Holiday(this.addDays(50,easterSunday),'2. pinsedag'));
        return holydays;
    }
    private getEasterSunday (InputYear:number) {
        var a = InputYear % 19;
        var b = Math.floor(InputYear/100);
        var c = InputYear % 100;
        var d = Math.floor(b/4);
        var e = b % 4;
        var f = Math.floor((b+8)/25);  	
        var g = Math.floor((b-f+1)/3);
        var h = (19*a+b-d-g+15) % 30;
        var i = Math.floor(c/4);
        var k = c % 4;
        var l = (32 + 2*e + 2* i - h - k) % 7;
        var m = Math.floor((a+11*h+22*l)/451);
        var n = Math.floor((h+l-7*m+114)/31);
        var p = (h+l-7*m+114) % 31;
        p++;
        var es = moment(p+"-"+n+"-"+InputYear, "DD-MM-YYYY"); 	
        return es;
    }
    private addDays(days: number, orgDate: Date): Date {
        var date = new Date(orgDate.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
}