import React from "react";
import "./Month.css";
import { Day } from "./Day";
import { PoDay } from "./PoDay";
import { Holiday } from "../Services/HolidayService";
import { adalFetch } from "react-adal";
import { authContext } from "../Auth/auth";
import { ConfigService } from "../Services/ConfigService";
import { MyDate } from "../Model/MyDate";

export interface IMonthProps {
  month: number;
  year: number;
  holidays: Holiday[];
  vacations: PoDay[];
  activity: number;
  updateVacations(days: PoDay[]): void;
}

export class Month extends React.Component<IMonthProps, any> {
  config: ConfigService;
  constructor(props: any) {
    super(props);
    this.state = {
      month: parseInt(props.month),
      year: parseInt(props.year),
      holidays: props.holidays,
      vacations: props.vacations,
      activity: props.activity,
      spinningWeeks: new Array<number>()
    };
    this.selectWeek = this.selectWeek.bind(this);
    this.config = new ConfigService();
  }

  getNumberOfWeeks(): number {
    var d = this.getDaysBeforeFirst();
    var p = this.getDaysOfMonth();
    if (d + p === 28) return 4;
    if (d + p <= 35) return 5;
    return 6;
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      month: parseInt(nextProps.month),
      year: parseInt(nextProps.year),
      holidays: nextProps.holidays,
      vacations: nextProps.vacations,
      activity: nextProps.activity
    });
  }

  render() {
    return (
      <div className="testing">
        <div className="small">
          <h1 className="title">{this.getMonthName(this.state.month)}</h1>
          <div className="weekNumsAndDays">
            <div className="weekNums">{this.printWeekNums()}</div>
            <div className="days">
              {this.printEmpty()}
              {this.printDays()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  selectWeek(event: any) {
    const weekNum = parseInt(event.currentTarget.innerText);

    this.setState({
      spinningWeeks: [weekNum, ...this.state.spinningWeeks]
    });
    const currentWeekDay = this.getDateOfISOWeek(weekNum, this.state.year);
    let days = new Array<MyDate>();
    for (let i = 0; i < 5; i++) {
      var d = new Date(currentWeekDay.valueOf() + 864e5 * i);
      days.push(new MyDate(d.getFullYear(), d.getMonth() + 1, d.getDate()));
    }

    days = days.filter(
      x =>
        this.state.holidays.find(
          (y: PoDay) =>
            y.date.year === x.year &&
            y.date.month === x.month &&
            y.date.day === x.day
        ) === undefined
    );
    var vac = this.state.vacations as PoDay[];

    var today = new Date();
    var todayDatePart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    var turnOff = this.allDaysSetBefore(days);
    if (turnOff) {
      vac = vac.filter((x: PoDay) => {
        const p = new Date(x.date.year, x.date.month - 1, x.date.day, 0, 0);
        const p2 = new Date(
          currentWeekDay.getFullYear(),
          currentWeekDay.getMonth(),
          currentWeekDay.getDate()
        );
        const p3 = new Date(
          days[days.length - 1].year,
          days[days.length - 1].month - 1,
          days[days.length - 1].day
        );
        return p.getTime() < p2.getTime() || p.getTime() > p3.getTime();
      });
    } else {
      for (let i2 of days) {
        if (i2.getTime() < todayDatePart.getTime()) continue;
        var p = vac.find(
          (x: PoDay) =>
            x.date.year === i2.year &&
            x.date.month === i2.month &&
            x.date.day === i2.day
        );
        if (p !== undefined) {
          p.type = this.props.activity.toString();
        } else {
          vac.push(new PoDay(i2, this.props.activity.toString()));
        }
      }
    }
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${this.config.url}/api/holiday/week`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ days: days, type: this.props.activity })
      }
    ).then(x => {
      this.setState({ vacations: vac }, () =>
        this.props.updateVacations(this.state.vacations)
      );
      this.setState({
        spinningWeeks: this.state.spinningWeeks.filter(
          (y: number) => y !== weekNum
        )
      });
    });
  }

  allDaysSetBefore(days: Array<MyDate>): boolean {
    for (let d of days) {
      var t = this.state.vacations.find(
        (x: PoDay) =>
          x.date.year === d.year &&
          x.date.month === d.month &&
          x.date.day === d.day
      );
      if (t === undefined || t.type !== this.state.activity.toString())
        return false;
    }
    return true;
  }

  getDateOfISOWeek(week: number, year: number) {
    var simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  printWeekNums(): React.ReactNode {
    var v = new Date(this.state.year, this.state.month - 1, 1);
    v.setDate(v.getDate() + 3 - ((v.getDay() + 6) % 7));
    var week1 = new Date(v.getFullYear(), 0, 4);
    var v2 =
      1 +
      Math.round(
        ((v.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      );
    var list = [];
    var isJanAndSmallWeek = false;
    if (this.state.month === 1 && v2 > 50) isJanAndSmallWeek = true;
    for (var i = 0; i < this.getNumberOfWeeks(); i++) {
      list.push(i);
    }

    return list.map(x => {
      const weeknumber = isJanAndSmallWeek && x > 0 ? x : x + v2;

      if (
        this.state.spinningWeeks.findIndex((y: number) => y === weeknumber) >= 0
      ) {
        return <div className="weekNum" key={x}></div>;
      }

      return (
        <div className="weekNum" onClick={this.selectWeek} key={x}>
          {isJanAndSmallWeek && x > 0 ? x : x + v2}
        </div>
      );
    });
  }

  printDays(): React.ReactNode {
    var list = [];
    for (var i = 0; i < this.getDaysOfMonth(); i++) {
      list.push(i);
    }
    return list.map(x => {
      return (
        <Day
          activity={this.props.activity}
          day={x + 1}
          key={x + 1}
          month={this.state.month}
          year={this.state.year}
          holidays={this.state.holidays}
          vacations={this.state.vacations}
          updateVacations={this.props.updateVacations}
        />
      );
    });
  }

  printEmpty(): React.ReactNode {
    var v = this.getDaysBeforeFirst();
    var list = [];
    for (var i = 0; i < v; i++) {
      list.push(i);
    }
    return list.map(x => {
      return <div key={x} />;
    });
  }

  getDaysBeforeFirst() {
    var v = new Date(this.state.year, this.state.month - 1, 1);
    return (v.getDay() + 6) % 7;
  }

  getDaysOfMonth(): number {
    var v = new Date(this.state.year, this.state.month, 0);
    return v.getDate();
  }

  getMonthName(month: number): string {
    switch (month) {
      case 1:
        return "Januar";
      case 2:
        return "Februar";
      case 3:
        return "Mars";
      case 4:
        return "April";
      case 5:
        return "Mai";
      case 6:
        return "Juni";
      case 7:
        return "Juli";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "Oktober";
      case 11:
        return "November";
      case 12:
        return "Desember";
      default:
        return "";
    }
  }
}
