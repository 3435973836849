import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { ReportDialogProps } from "./ReportDialogProps";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function ReportDialog(props: ReportDialogProps) {
  const { onClose, ...other } = props;
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<Date | null>(
    new Date()
  );
  const [selectedDateTo, setSelectedDateTo] = React.useState<Date | null>(
    new Date()
  );
  function handleClose() {
    onClose(null);
  }

  function handleGenerateClick() {
    onClose({ from: selectedDateFrom, to: selectedDateTo });
  }

  function handleDateChangeFrom(date: Date | null) {
    setSelectedDateFrom(date);
  }
  function handleDateChangeTo(date: Date | null) {
    setSelectedDateTo(date);
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...other}
    >
      <DialogTitle id="alert-dialog-title">
        {"Generer fraværsrapport"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Velg fra og til for intervall
        </DialogContentText>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="mui-pickers-date-from"
            label="Fra"
            value={selectedDateFrom}
            onChange={handleDateChangeFrom}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="mui-pickers-date-to"
            label="Til og med"
            value={selectedDateTo}
            onChange={handleDateChangeTo}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGenerateClick} color="primary">
          Generer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
