import {
    AuthenticationContext,AdalConfig
} from 'react-adal';

//export const endpoint = 'https://<tenant name>.sharepoint.com/';
// App Registration ID
const appId = '491f0b99-8aa8-4bbf-8247-5621a11a4e93';
export const adalConfig: AdalConfig = {
  cacheLocation: 'localStorage',
  clientId: appId,
//   endpoints: {
//       api:endpoint
//   },
  postLogoutRedirectUri: window.location.origin,
  tenant: 'e91a9e2f-0ba3-43ac-8005-550f20b326fe'
};
export const authContext = new AuthenticationContext(adalConfig);
