import React from "react";
import "./Day.css";
import { adalFetch } from "react-adal";
import { authContext } from "../Auth/auth";
import { ConfigService } from "../Services/ConfigService";
import spinner from "../Resources/ripple.svg";
import { PoDay } from "./PoDay";
import Tooltip from "@material-ui/core/Tooltip";
import { Holiday } from "../Services/HolidayService";
import { MyDate } from "../Model/MyDate";

export interface IDayProps {
  day: number;
  month: number;
  year: number;
  holidays: Holiday[];
  vacations: PoDay[];
  activity: number;
  updateVacations(days: PoDay[]): void;
}
export interface IDayState extends IDayProps {
  isLoading: boolean;
}

export class Day extends React.Component<IDayProps, IDayState> {
  config: ConfigService;
  constructor(props: any) {
    super(props);
    this.state = {
      day: parseInt(props.day),
      month: parseInt(props.month),
      year: parseInt(props.year),
      holidays: props.holidays,
      vacations: props.vacations,
      isLoading: false,
      activity: props.activity,
      updateVacations: props.updateVacations
    };
    this.selectDay = this.selectDay.bind(this);
    this.getToolTip = this.getToolTip.bind(this);
    this.config = new ConfigService();
  }
  componentWillReceiveProps(nextProps: any) {
    this.setState({
      month: parseInt(nextProps.month),
      year: parseInt(nextProps.year),
      holidays: nextProps.holidays,
      vacations: nextProps.vacations
    });
  }
  render() {
    if (this.state.isLoading)
      return (
        <div
          onClick={this.selectDay}
          className={
            "box " +
            this.pastTimeClass() +
            " " +
            this.vacationClass() +
            " " +
            this.holidayClass()
          }
        >
          <img src={spinner} className="App-logo" alt="logo" />
        </div>
      );
    else
      return (
        <Tooltip title={this.getToolTip()}>
          <div
            onClick={this.selectDay}
            className={
              "box " +
              this.pastTimeClass() +
              " " +
              this.vacationClass() +
              " " +
              this.holidayClass()
            }
          >
            {this.state.day}
          </div>
        </Tooltip>
      );
  }

  getToolTip(): string {
    const p = this.isVacation();
    if (p !== undefined)
      switch (p.type) {
        case "901":
          return "Sykemelding (901)";
        case "904":
          return "Ferie (904)";
        case "905":
          return "Foreldrepermisjon (905)";
        case "906":
          return "Kompetanse (906)";
        case "907":
          return "Kompetanse felles (907)";
        case "908":
          return "Velferdspermisjon (908)";
        case "909":
          return "Offentlige verv (909)";
        case "911":
          return "Permisjon med lønn (911)";
        case "912":
          return "Permisjon uten lønn (912)";
        default:
          return "";
      }
    const p2 = this.isHoliday();
    if (p2 !== undefined) {
      return p2.description;
    }
    return "";
  }
  selectDay() {
    var d = new MyDate(this.state.year, this.state.month, this.state.day);
    var today = new Date();
    var todayDatePart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    var activity = this.props.activity;
    if (d.getTime() < todayDatePart.getTime() || this.isHoliday() !== undefined)
      return;
    this.setState({ isLoading: true });
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${this.config.url}/api/holiday`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          day: this.state.day,
          month: this.state.month,
          year: this.state.year,
          type: activity
        })
      }
    ).then(x => {
      this.setState({ isLoading: false });
      if (x.ok !== true) {
      } else {
        var v = this.state.vacations;
        var p = v.find(
          x =>
            x.date.day === d.day &&
            x.date.month === d.month &&
            x.date.year === d.year
        );
        if (p != null) {
          if (p.type !== activity.toString()) {
            p.type = activity.toString();
            this.setState({ vacations: this.state.vacations }, () =>
              this.props.updateVacations(this.state.vacations)
            );
          } else {
            this.setState(
              {
                vacations: this.state.vacations.filter(
                  x =>
                    x.date.day !== d.day ||
                    x.date.month !== d.month ||
                    x.date.year !== d.year
                )
              },
              () => this.props.updateVacations(this.state.vacations)
            );
          }
        } else {
          this.state.vacations.push(new PoDay(d, activity.toString()));
          this.setState({ vacations: this.state.vacations }, () =>
            this.props.updateVacations(this.state.vacations)
          );
        }
      }
    });
  }

  isVacation(): PoDay | undefined {
    return this.state.vacations.find(x => {
      return (
        x.date.day === this.state.day &&
        x.date.month === this.state.month &&
        x.date.year === this.state.year
      );
    });
  }
  isHoliday(): Holiday | undefined {
    var d = new Date(this.state.year, this.state.month - 1, this.state.day);
    if (d.getDay() === 0) return new Holiday(d, "Søndag");
    if (d.getDay() === 6) return new Holiday(d, "Lørdag");
    var ret = this.state.holidays.find(
      x =>
        x.date.getDate() === d.getDate() && x.date.getMonth() === d.getMonth()
    );
    if (
      this.state.holidays.find(
        x =>
          x.date.getDate() === d.getDate() && x.date.getMonth() === d.getMonth()
      ) != null
    )
      return ret;
    return undefined;
  }
  pastTimeClass(): string {
    var today = new Date();
    var todayDatePart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    var d = new Date(this.state.year, this.state.month - 1, this.state.day);
    if (d.getTime() < todayDatePart.getTime()) return "past";
    var v = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    if (this.isHoliday() === undefined && d >= v) {
      return "future";
    }
    return "";
  }

  vacationClass(): string {
    const p = this.isVacation();
    if (p === undefined) return "";
    switch (p.type) {
      case "901":
        return "leave_901";
      case "904":
        return "leave_904";
      case "905":
        return "leave_905";
      case "906":
        return "leave_906";
      case "907":
        return "leave_907";
      case "908":
        return "leave_908";
      case "909":
        return "leave_909";
      case "911":
        return "leave_911";
      case "912":
        return "leave_912";
      default:
        return "";
    }
  }

  holidayClass(): string {
    return this.isHoliday() !== undefined ? "holiday" : "";
  }
}
