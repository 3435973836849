import { adalFetch, runWithAdal } from "react-adal";
import React, { Component } from 'react';
import Year from './Components/Year';
import './App.css';
import { authContext } from './Auth/auth';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ConfigService } from "./Services/ConfigService";
import MyMenu from "./Components/MyMenu";
import {  MenuItem, Button, Menu } from "@material-ui/core";
import logo from './Resources/Logo_mot_sort_BG.svg';

class App extends Component<any, any>{
  config: ConfigService;

  abortcontroller = new AbortController();
  largeDevice = false;

  constructor(props: any) {
    super(props);
    this.config = new ConfigService();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  componentDidMount() {


    var v = new Date();
    this.setState({ year: v.getFullYear(), anchorEl: null, menuLabel: 'Ferie', activity: 904 })


    adalFetch(authContext, '491f0b99-8aa8-4bbf-8247-5621a11a4e93',
      fetch, `${this.config.url}/api/holiday/isUser`, {
        signal: this.abortcontroller.signal

      }).then(x => { return x.json(); })
      .then(x => {
        this.setState({ isUser: x.isUser, isUserEmail: x.email });
      }).catch(x => console.log(x));
    adalFetch(authContext, '491f0b99-8aa8-4bbf-8247-5621a11a4e93',
      fetch, `${this.config.url}/api/holiday/isAdmin`, {
        signal: this.abortcontroller.signal
      }).then(x => x.json())
      .then(x => {
        this.setState({ isAdmin: x });
      });

    adalFetch(authContext, '491f0b99-8aa8-4bbf-8247-5621a11a4e93',
      fetch, `${this.config.url}/api/holiday/username`, {}).then(x => x.json())
      .then(x => {
        this.setState({ username: x })
      });
  }
  componentWillUnmount() {
    this.abortcontroller.abort();
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }
  handleMenuClick(activity: number) {
    var label = '';
    switch (activity) {
      case 901: label = 'Syk med egenmelding'; break;
      case 905: label = 'foreldrepermisjon'; break;
      case 906: label = 'Kompetanse egen'; break;
      case 907: label = 'Kompetanse felles'; break;
      case 908: label = 'Velferdspermisjon'; break;
      case 909: label = 'Offentlige verv'; break;
      case 911: label = 'Permisjon med lønn'; break;
      case 912: label = 'Permisjon uten lønn'; break;
      case 904: label = 'Ferie'; break;
    }
    this.setState({ menuLabel: label, anchorEl: null, activity: activity });
  }

  handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget })
  }
  render() {
    runWithAdal(authContext, () => { }, false);
    if (this.state == null || this.state.isUser == null || this.state.isAdmin == null) {
      return (<div>Loading...</div>);
    }

    if (!this.state.isUser) {
      return (<div className="App">
        <div>
          <AppBar>
            <Toolbar>
              <Typography variant="h6">
                Enso Ferieplanlegger
          </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <div>
          <h1>yolo</h1>
          <h4>finner ikke bruker med epost '{this.state != null ? this.state.isUserEmail : ""}' i PowerOffice. Sjekk at du har bruker og epostadresse er riktig</h4>
        </div>
      </div >)
    }
    else {
      return (<div className="App">
        <AppBar position="static">
          <Toolbar className="toolbar">
            <div className="mygrid">

              <div>
                {this.state != null && this.state.isAdmin && <MyMenu />}
              </div>
              
              <div className="headertitle">
              <img src={logo} className="logo" alt="Ensō" />  
                  <span className="text">FERIE</span>
                </div>
              <div>
                {this.state.username}
              </div>
              <div className="myButton">
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} color="inherit" className="myButton">
                  {this.state.menuLabel}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={() => this.handleMenuClick(904)}>Ferie (904)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(905)}>Foreldrepermisjon (905)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(901)}>Sykemelding (901)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(906)}>Kompetanse (906)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(907)}>Kompetanse felles (907)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(908)}>Velferdspermisjon (908)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(909)}>Offentlige verv (909)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(911)}>Permisjon med lønn (911)</MenuItem>
                  <MenuItem onClick={() => this.handleMenuClick(912)}>Permisjon uten lønn (912)</MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Year which={this.state != null ? this.state.year : 2000} activity={this.state.activity} />
      </div>)
    }
  }
}
export default App;
