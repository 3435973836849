import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, Icon } from "@material-ui/core";
import ReportDialog from "./ReportDialog";
import { ConfigService } from "../Services/ConfigService";
import { adalFetch } from "react-adal";
import { authContext } from "../Auth/auth";
import { saveAs } from "file-saver";
import b64toBlob from "b64-to-blob";

export default function MyMenu() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const config = new ConfigService();
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleReport() {
    setAnchorEl(null);
    setOpen(true);
  }
  function generateReport(from: Date, to: Date) {
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${config.url}/api/holiday/report`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ from, to })
      }
    )
      .then(x => x.json())
      .then(x => {
        var v = b64toBlob(
          x,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        saveAs(v, "fraværsrapport.xlsx");
      });
  }

  const handleCloseDialog = (value: any) => {
    setOpen(false);
    if (value && value.from != null && value.to != null) {
      generateReport(value.from, value.to);
    }
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon>menu</Icon>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReport}>Rapport</MenuItem>
      </Menu>
      <ReportDialog open={open} onClose={handleCloseDialog} />
    </div>
  );
}
