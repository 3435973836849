import React from "react";
import { HolidayService } from "../Services/HolidayService";
import { Month } from "./Month";
import "./Year.css";
import { adalFetch } from "react-adal";
import { authContext } from "../Auth/auth";
import { ConfigService } from "../Services/ConfigService";
import { PoDay } from "./PoDay";

export default class Year extends React.Component<any, any> {
  holidayService: HolidayService;
  config: ConfigService;
  constructor(props: any) {
    super(props);
    this.holidayService = new HolidayService();
    this.state = {
      vacations: null,
      year: parseInt(this.props.which)
    };
    this.nextYear = this.nextYear.bind(this);
    this.prevYear = this.prevYear.bind(this);
    this.updateVacations = this.updateVacations.bind(this);
    this.config = new ConfigService();
  }

  componentWillUnmount() {}
  componentDidMount() {
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${this.config.url}/api/holiday/${this.state.year}`,
      {}
    )
      .then(x => x.json())
      .then(x => {
        this.setState({
          vacations: x.map((y: PoDay) => new PoDay(y.date, y.type))
        });
      });
  }
  updateVacations(vacations: PoDay[]) {
    this.setState({
      vacations: vacations
    });
  }

  render() {
    var holidays = this.holidayService.createHolidays(this.state.year);
    var list = [];
    for (var i = 0; i < 12; i++) {
      list.push(i);
    }
    if (this.state.vacations == null) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <div className="holiday-count">
            <div className="holiday-count__content">
              Antall registrerte feriedager:{" "}
              <span className="holiday-count__numberOfDays">
                {
                  this.state.vacations.filter((y: PoDay) => y.type === "904")
                    .length
                }
              </span>
            </div>
          </div>

          <div className="years">
            <h2 className="yearBox yearBoxPrevNext" onClick={this.prevYear}>
              {this.state.year - 1}
            </h2>
            <h1 className="yearBox">{this.state.year}</h1>
            <h2 className="yearBox yearBoxPrevNext" onClick={this.nextYear}>
              {parseInt(this.state.year) + 1}
            </h2>
          </div>

          <div className="wrapperYear">
            {list.map(x => {
              return (
                <Month
                  updateVacations={this.updateVacations}
                  activity={this.props.activity}
                  month={x + 1}
                  key={x + 1}
                  vacations={this.state.vacations}
                  holidays={holidays}
                  year={this.state.year}
                >
                  {x}
                </Month>
              );
            })}
          </div>
        </div>
      );
    }
  }
  nextYear() {
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${this.config.url}/api/holiday/${this.state.year + 1}`,
      {}
    )
      .then(x => x.json())
      .then(x => {
        this.setState({
          vacations: x.map((y: PoDay) => new PoDay(y.date, y.type))
        });
        this.setState({ year: this.state.year + 1 });
      });
  }
  prevYear() {
    adalFetch(
      authContext,
      "491f0b99-8aa8-4bbf-8247-5621a11a4e93",
      fetch,
      `${this.config.url}/api/holiday/${this.state.year - 1}`,
      {}
    )
      .then(x => x.json())
      .then(x => {
        this.setState({
          vacations: x.map((y: PoDay) => new PoDay(y.date, y.type))
        });
        this.setState({ year: this.state.year - 1 });
      });
  }
}
